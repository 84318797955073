import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import logo_color from "../../static/images/logo_color.png"
import { Link } from "../components/CustomLinks"

const Page_404 = () => {
  return (
    <Layout>
      <Helmet>
        <title>EOG Resources, Inc. – Page Not Found</title>
      </Helmet>
      <section className="page home-page last-page">
        <div className="max-width-container ">
          <img src={logo_color} alt="Not Found" />
          <h2>Page Not Found</h2>
          <Link to="/">Return Home</Link>
        </div>
      </section>
    </Layout>
  )
}
export default Page_404
